import { BreadCrumbs } from '../../containers'
import { RouteManager } from '../../containers/RouteManager'
import { SettlementRequestsManager } from '../../containers/SettlementRequestsManager'
import { getRouteByName } from '../../routes'
import trans from '../../trans'
import { Section } from '../ui/Section'
import { SectionHeader } from '../ui/SectionHeader'
import React from 'react'
import { Link } from 'react-router-dom'
import PaymentsPageFilters from '../PaymentsPageFilters'
import PaymentsPageTable from '../PaymentsPageTable'
import useAccounts from './hooks/useAccounts'
import { LoadingOverlay } from '../ui/LoadingOverlay'
import AccountsPageTable from '../AccountsPageTable'
import AccountsPageFilters from '../AccountsPageFilters'

function AccountsPage() {
  const account = '** **** **** **** **** XXXXX'
  const { data, paginator, loading, setPage } = useAccounts()

  return (
    <div>
      <BreadCrumbs>
        <Link to={getRouteByName('main', 'dashboard')}>{trans('global.dashboard')}</Link>
        <Link to={getRouteByName('main', 'my-card')}>{trans('main-menu.my-card')}</Link>
        <Link to={getRouteByName('main', 'my-card-account')}>
          {trans('main-menu.my-card-accounts')}
        </Link>
      </BreadCrumbs>

      <Section className={'my-cards account-page'} noBorder>
        <SectionHeader
          className={'my-cards__header'}
          caption={`${trans('my-card.account')}  ${account}`}
        >
          <div>
            {trans('my-card.account_details_for_period')}: 01.01.2024 - 31.01.2024 <br />
            {trans('my-card.account_details_id')}: B202400001
          </div>
        </SectionHeader>

        <RouteManager>
          {({ getRouteByName, push }) => (
            <div>
              <AccountsPageFilters />
              {loading && <LoadingOverlay />}
              {!loading && (
                <AccountsPageTable data={data} paginator={paginator} setPage={setPage} />
              )}
            </div>
          )}
        </RouteManager>
      </Section>
    </div>
  )
}

export default AccountsPage
