import { useEffect, useState } from 'react'
import APIClient from '../../../services/APIClient'
import { Paginator } from '../../../types/response'
import { Statement } from '../../../types/statements'

export default function useStatements() {
  const [data, setData] = useState<Statement[]>([])
  const [paginator, setPaginator] = useState<Paginator>({} as Paginator)
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    setLoading(true)

    APIClient.getMyCardStatements()
      .then((response) => {
        setData(response.data)
        setPaginator(response.paginator)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [])

  return { data, paginator, loading }
}
