import { useEffect, useMemo, useState } from 'react'
import { GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community'
import trans from '../../../trans'
import dateFormatter from '../../AgGrid/ValueFormatter/dateFormatter'
import { amountFormatter } from '../../AgGrid/ValueFormatter/amountFormatter'
import defaultFormatter from '../../AgGrid/ValueFormatter/defaultFormatter'
import StatementStatusRenderer from '../../AgGrid/CellRenderer/StatementStatusRenderer'
import { Statement } from '../../../types/statements'

export function useGridOptions() {
  const [api, setApi] = useState<GridApi>(null)
  const [gridOptions, setGridOptions] = useState<GridOptions<Statement>>({
    domLayout: 'autoHeight',
    defaultColDef: {
      suppressSizeToFit: false,
      suppressMenu: true,
      suppressMovable: true,
    },
    onGridReady(event: GridReadyEvent<Statement>) {
      setApi(event.api)
    },
    rowSelection: 'multiple',
    suppressRowClickSelection: true,
    columnDefs: [
      {
        headerName: '',
        field: 'id',
        headerCheckboxSelection: true,
        checkboxSelection: true,
        valueFormatter: () => '',
        minWidth: 50,
        maxWidth: 50,
      },
      {
        headerName: trans('my-card.statement_th_statement_date'),
        field: 'created_at',
        valueFormatter: dateFormatter,
        minWidth: 140,
        maxWidth: 140,
      },
      {
        headerName: trans('my-card.statement_th_statement_id'),
        field: 'id',
        valueFormatter: defaultFormatter,
        minWidth: 150,
        maxWidth: 150,
      },
      {
        headerName: trans('my-card.statement_th_name'),
        field: 'description',
        valueFormatter: defaultFormatter,
        minWidth: 150,
      },
      {
        headerName: trans('my-card.statement_th_statement_amount'),
        field: 'statement_value',
        valueFormatter: amountFormatter('statement_currency'),
        minWidth: 150,
        maxWidth: 150,
        type: 'numericColumn',
      },
      {
        headerName: trans('my-card.statement_th_requested_by'),
        field: 'created_by',
        valueFormatter: defaultFormatter,
        minWidth: 180,
        maxWidth: 180,
      },
      {
        headerName: trans('my-card.statement_th_status'),
        field: 'status',
        cellRenderer: StatementStatusRenderer,
        minWidth: 150,
        maxWidth: 150,
      },
    ],
  })

  const onResize = useMemo(() => {
    return () => {
      if (api) {
        api.sizeColumnsToFit()
      }
    }
  }, [api])

  useEffect(() => {
    onResize()

    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [api])

  return gridOptions
}
