import React, { useCallback, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { useGridOptions } from './hooks/useGridOptions'
import Pagination from '../SettingsPage/CompanyPage/Pagination/Pagination'
import { CellClickedEvent } from 'ag-grid-community'
import { Statement } from '../../types/statements'
import { Row } from '../ui/AccordionTable'
import { Col, Radio } from '../../ui'
import { ExpenseRequestActions } from '../ExpenseRequestAccountingPage/ExpenseRequestActions'
import trans from '../../trans'
import Button from '../ui/ButtonComponent'

function StatementsPageTable({ data, paginator, navigate }) {
  const gridOptions = useGridOptions()
  const [selected, setSelected] = useState<Statement[]>([])

  const onCellClicked = useCallback(
    (event: CellClickedEvent<Statement>) => {
      if (event.column.getId() === 'id') {
        event.node.setSelected(!event.node.isSelected())

        return
      }

      navigate(event.data.id)
    },
    [navigate],
  )

  return (
    <>
      <div className={'ag-theme-alpine'}>
        <AgGridReact
          gridOptions={gridOptions}
          rowData={data}
          rowHeight={58}
          headerHeight={40}
          onCellClicked={onCellClicked}
          onSelectionChanged={(e) => {
            setSelected(e.api.getSelectedRows())
          }}
        />
      </div>

      {selected.length > 0 && (
        <Row className='notification-bar'>
          <Col sm={12} is_pull_end>
            <div className='notification-bar__row'>
              <ExpenseRequestActions hideNotApplicable>
                <div className='react-select__option'>
                  <Radio
                    name='status'
                    disabled={!selected.length}
                    label={trans('request.send-reminder') + ` (${selected.length})`}
                    onClick={() => {}}
                  />
                </div>

                <div className='react-select__option'>
                  <Radio
                    name='status'
                    disabled={!selected.length}
                    label={trans('global.export') + ` (${selected.length})`}
                    onClick={() => {}}
                  />
                </div>

                <div className='react-select__option'>
                  <Radio
                    name='status'
                    disabled={!selected.length}
                    label={trans('global.print') + ` (${selected.length})`}
                    onClick={() => {}}
                  />
                </div>
              </ExpenseRequestActions>

              <Button primary xs disabled={!selected.length} onClick={() => this.applyAction()}>
                {trans('global.perform')}
              </Button>
            </div>
          </Col>
        </Row>
      )}

      <Pagination
        page={paginator.page}
        total={paginator.total}
        perPage={paginator.per_page}
        changePage={() => {}}
      />
    </>
  )
}

export default StatementsPageTable
