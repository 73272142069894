import React, { useCallback, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { useGridOptions } from './hooks/useGridOptions'
import Pagination from '../SettingsPage/CompanyPage/Pagination/Pagination'
import { Statement } from '../../types/statements'
import { CellClickedEvent } from 'ag-grid-community'
import { Row } from '../ui/AccordionTable'
import { Col, Radio } from '../../ui'
import { ExpenseRequestActions } from '../ExpenseRequestAccountingPage/ExpenseRequestActions'
import trans from '../../trans'
import Button from '../ui/ButtonComponent'
import { Account } from '../../types/account'
import { REQUEST_DECISIONS } from '../../store/app/request-action'
import { DatePickerField } from '../ui/Form'
import moment from 'moment'

function AccountsPageTable({ data, paginator, setPage }) {
  const gridOptions = useGridOptions()
  const [selected, setSelected] = useState<Account[]>([])
  const [exportDate, setExportDate] = useState(moment())

  const onCellClicked = useCallback((event: CellClickedEvent<Account>) => {
    if (event.column.getId() === 'id') {
      event.node.setSelected(!event.node.isSelected())

      return
    }
  }, [])

  return (
    <>
      <div className={'ag-theme-alpine'}>
        <AgGridReact
          gridOptions={gridOptions}
          rowData={data}
          rowHeight={58}
          headerHeight={40}
          onCellClicked={onCellClicked}
          onSelectionChanged={(e) => {
            setSelected(e.api.getSelectedRows())
          }}
        />
      </div>

      {selected.length > 0 && (
        <Row className='notification-bar'>
          <Col sm={12} is_pull_end>
            <div className='notification-bar__row'>
              <ExpenseRequestActions hideNotApplicable>
                <div className='react-select__option'>
                  <Radio
                    name='status'
                    disabled={!selected.length}
                    label={trans('global.export') + ` (${selected.length})`}
                    onClick={() => {}}
                  />

                  <DatePickerField
                    usePortal
                    maxDate={new Date()}
                    value={exportDate}
                    onChange={(date) => {
                      setExportDate(date)
                    }}
                  />
                </div>
              </ExpenseRequestActions>

              <Button primary xs disabled={!selected.length} onClick={() => this.applyAction()}>
                {trans('global.perform')}
              </Button>
            </div>
          </Col>
        </Row>
      )}

      <Pagination
        page={paginator.page}
        total={paginator.total}
        perPage={paginator.per_page}
        changePage={(page) => setPage(page)}
      />
    </>
  )
}

export default AccountsPageTable
