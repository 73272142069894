import { useEffect, useMemo, useState } from 'react'
import { GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community'
import trans from '../../../trans'
import dateFormatter from '../../AgGrid/ValueFormatter/dateFormatter'
import { amountFormatter } from '../../AgGrid/ValueFormatter/amountFormatter'
import defaultFormatter from '../../AgGrid/ValueFormatter/defaultFormatter'
import { Account } from '../../../types/account'

export function useGridOptions() {
  const [api, setApi] = useState<GridApi>(null)
  const [gridOptions, setGridOptions] = useState<GridOptions<Account>>({
    domLayout: 'autoHeight',
    defaultColDef: {
      suppressSizeToFit: false,
      suppressMenu: true,
      suppressMovable: true,
    },
    onGridReady(event: GridReadyEvent<Account>) {
      setApi(event.api)
    },
    rowSelection: 'multiple',
    suppressRowClickSelection: true,
    columnDefs: [
      {
        headerName: '',
        field: 'id',
        headerCheckboxSelection: true,
        checkboxSelection: true,
        valueFormatter: () => '',
        minWidth: 50,
        maxWidth: 50,
      },
      {
        headerName: trans('my-card.account_date'),
        field: 'account_date',
        valueFormatter: dateFormatter,
        minWidth: 150,
        maxWidth: 150,
      },
      {
        headerName: trans('my-card.account_details'),
        field: 'description',
        valueFormatter: defaultFormatter,
        wrapText: true,
        autoHeight: true,
      },
      {
        headerName: trans('my-card.account_card'),
        field: 'card',
        minWidth: 150,
        maxWidth: 150,
      },
      {
        headerName: trans('my-card.account_employee'),
        field: 'employee',
        minWidth: 150,
        maxWidth: 150,
      },
      {
        headerName: trans('my-card.account_amount'),
        field: 'amount',
        valueFormatter: amountFormatter('amount_currency'),
        minWidth: 150,
        maxWidth: 150,
        type: 'numericColumn',
      },
      {
        headerName: trans('my-card.account_balance'),
        field: 'balance',
        valueFormatter: amountFormatter('balance_currency'),
        minWidth: 120,
        maxWidth: 120,
        type: 'numericColumn',
      },
      {
        headerName: trans('my-card.account_status'),
        field: 'status',
        valueFormatter: defaultFormatter,
        minWidth: 150,
        maxWidth: 150,
      },
    ],
  })

  const onResize = useMemo(() => {
    return () => {
      if (api) {
        api.sizeColumnsToFit()
      }
    }
  }, [api])

  useEffect(() => {
    onResize()

    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [api])

  return gridOptions
}
