import React, { useEffect, useState } from 'react'
import { BreadCrumbs } from '../../containers'
import { getRouteByName } from '../../routes'
import trans from '../../trans'
import { SectionHeader } from '../ui/SectionHeader'
import { Section } from '../ui/Section'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

declare let embedKontomatik

export default function KontomatikPage() {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    const script = document.createElement('script')

    script.src = 'https://signin.kontomatik.com/assets/signin-widget.js'
    script.async = true
    script.onload = () => {
      setLoaded(true)

      embedKontomatik({
        client: 'mindento',
        divId: 'kontomatik',
        onSuccess: (target, sessionId, sessionIdSignature, options) => {
          console.log('onSuccess', {
            target,
            sessionId,
            sessionIdSignature,
            options,
          })
        },
      })
    }

    document.body.appendChild(script)
    return () => {
      setLoaded(false)
      document.body.removeChild(script)
    }
  }, [])

  return (
    <div>
      <BreadCrumbs>
        <Link to={getRouteByName('main', 'dashboard')}>{trans('global.dashboard')}</Link>
        <Link to={getRouteByName('main', 'kontomatik')}>{'Kontomatik Demo'}</Link>
      </BreadCrumbs>
      <Section noBorder>
        <Helmet title={'Kontomatik Demo'} />

        <SectionHeader caption={'Kontomatik Demo'} />

        <div id='kontomatik'></div>
      </Section>
    </div>
  )
}
