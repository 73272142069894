import { Paginator } from './response'

export enum StatementStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}

export interface Statement {
  id: string
  description: string
  statement_value: number
  statement_currency: string
  status: StatementStatus
  created_at: string
  created_by: string
}

export interface StatementResponse {
  data: Statement[]
  summary?: StatementSummary[]
  paginator: Paginator
}

export interface StatementSummary {
  name: string
  currency: string
  value: number
}
