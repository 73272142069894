import { useCallback, useEffect, useState } from 'react'
import APIClient from '../../../services/APIClient'
import { Paginator } from '../../../types/response'
import { Account } from '../../../types/account'

export default function useAccounts() {
  const [data, setData] = useState<Account[]>([])
  const [paginator, setPaginator] = useState<Paginator>({} as Paginator)
  const [loading, setLoading] = useState<boolean>(true)

  const setPage = useCallback(
    (page: number) => {
      if (paginator.page !== page) {
        loadItems(page)
      }
    },
    [paginator.page, loading],
  )

  const loadItems = (page: number) => {
    setLoading(true)

    return APIClient.getMyCardAccounts(page)
      .then((response) => {
        setData(response.data)
        setPaginator(response.paginator)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    loadItems(1)
  }, [])

  return { data, paginator, loading, setPage }
}
