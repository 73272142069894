import APIClient from '../../../services/APIClient'
import arrayMove from 'array-move'

export const CARD_STATUS_ADDING = 'ADDING'

export const CARD_TYPE_INDIVIDUAL = 'individual'
export const CARD_TYPE_CORPO = 'corpo'

// initial state
const initialState = {
  data: [],
  companies: [],
  isLoading: true,
  isLoaded: false,
}

// constants
export const MOUNT_POINT = 'charge-cards'

export const RESET = MOUNT_POINT + '::reset'
export const SET = MOUNT_POINT + '::set'
export const SETCOMPANIES = MOUNT_POINT + '::set-companies'
export const ADD = MOUNT_POINT + '::add'
export const DELETE = MOUNT_POINT + '::delete-card'
export const REORDER = MOUNT_POINT + '::reorder'

// actions
export const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  })
}

export const setData = (data) => (dispatch) => {
  dispatch({
    type: SET,
    payload: data,
  })
}

export const setCompanies = (companies) => (dispatch) => {
  dispatch({
    type: SETCOMPANIES,
    payload: companies,
  })
}

export const addCard =
  (type = 'individual', companies, userSlug = null) =>
  (dispatch) => {
    return APIClient.createPaymentCard(type, companies, userSlug).then((response) => {
      const form = document.createElement('form')
      form.method = 'POST'
      form.action = response.data.url
      form.target = '_blank'

      const formData = response.data.form_data
      for (const key in formData) {
        const input = document.createElement('input')
        input.type = 'hidden'
        input.name = key
        input.value = formData[key]
        form.appendChild(input)
      }

      document.body.appendChild(form)
      form.submit()
      document.body.removeChild(form)
    })
  }

export const reorderCards =
  (source, destination, userSlug = null) =>
  (dispatch, getState) => {
    dispatch({
      type: REORDER,
      payload: {
        source,
        destination,
      },
    })

    const cardSlugsReversed = getCards(getState()).map((card) => card.slug)

    return APIClient.updateCardsPriority(cardSlugsReversed, userSlug).then((response) => {
      dispatch(setData(response.data))
    })
  }

export const deleteCard =
  (slug, userSlug = null) =>
  (dispatch) => {
    return APIClient.deletePaymentCard(slug, userSlug).then((response) => {
      dispatch(setData(response.data))
    })
  }

// reducer
export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case RESET:
      return initialState
    case SET:
      return {
        ...state,
        data: payload.sort((card, card2) => card.priority - card2.priority),
        isLoading: false,
        isLoaded: true,
      }
    case SETCOMPANIES:
      return {
        ...state,
        companies: payload,
      }
    case REORDER:
      return {
        ...state,
        data: arrayMove(state.data, payload.source, payload.destination).map((card, index) => {
          return {
            ...card,
            priority: state.data.length - index - 1,
          }
        }),
      }
    default:
      return state
  }
}

// selectors
export const getState = (state) => state.get(MOUNT_POINT)
export const getCards = (state) => getState(state).data
export const getCompanies = (state) => getState(state).companies
export const isLoading = (state) => getState(state).isLoading
