import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Button from '../../ui/ButtonComponent'
import CardsTable from './partials/CardsTable'
import trans from '../../../trans'
import {
  addCard,
  getCards,
  isLoading,
  reset,
  setData,
  reorderCards,
} from '../../../store/app/charge-cards'
import SocketProviderService from '../../../utils/SocketProvider'
import { userProfile } from '../../../store/app/user-profile/providers/userProfile'
import APIClient from '../../../services/APIClient'
import { Loader } from '../../ui/LoadingOverlay/Loader'
import PropTypes from 'prop-types'

const SOCKET_CHANNEL = 'App.User.'
const SOCKET_EVENT = '.App\\CardManager\\Events\\CardUpdatedEvent'

class ChargeCards extends React.Component<any, any> {
  componentDidMount() {
    const {
      userProfile: {
        selectors: { data: user },
      },
    } = this.props

    this.fetchCards()

    SocketProviderService.subscribe(SOCKET_CHANNEL + user.id)(SOCKET_EVENT)(() => {
      this.fetchCards()
    })
  }

  componentWillUnmount() {
    const {
      userProfile: {
        selectors: { data: user },
      },
      reset,
    } = this.props
    SocketProviderService.unsubscribe(SOCKET_CHANNEL + user.id)(SOCKET_EVENT)
    reset()
  }

  fetchCards() {
    const { setData } = this.props

    return APIClient.getPaymentCards(
      this.props.subjectUser ? this.props.subjectUser.slug : null,
    ).then((response) => {
      setData(response.data)
    })
  }

  render() {
    const { cards, addCard, isLoading, reorderCards } = this.props
    const subjectUser = this.props.subjectUser ? this.props.subjectUser : null

    return (
      <div className='charge-cards has-loader'>
        <div className='charge-cards__header'>
          <h2 className='tabs__content-heading'>{trans('user.charge-cards')}</h2>

          <Button
            primary
            className='charge-cards__add-button'
            onClick={() =>
              addCard(
                'individual',
                null,
                this.props.subjectUser && this.props.subjectUser.slug
                  ? this.props.subjectUser.slug
                  : null,
              )
            }
          >
            <span className='charge-cards__plus-icon' aria-hidden='true' />
            <span className='charge-cards__add-button-text'>{trans('user.add-card')}</span>
          </Button>
        </div>

        {!isLoading ? (
          <CardsTable
            subjectUser={subjectUser}
            cards={cards}
            reorderCards={reorderCards}
            withPriority
            withCompany
          />
        ) : (
          <Loader />
        )}

        <div className='charge-cards__footer'>
          <img
            src='/images/fdp.png'
            alt='Logo First Data Polcard'
            className='charge-cards__certificate-img'
          />
          <img src='/images/pci.png' alt='Logo PCI' className='charge-cards__certificate-img' />
          <p className='charge-cards__clause'>
            Certyfikat zaświadcza, że dane transakcyjne – takie jak dane karty oraz numery PIN –
            przetwarzane we wszystkich punktach oznaczonych logo FirstData Polcard są szyfrowane,
            przesyłane i przechowywane z zachowaniem restrykcyjnych procedur i standardów
            bezpieczeństwa. Minimalizuje to ryzyko oszustw, co jest ważne zarówno dla właściciela
            karty, jak i punktu przyjmującego płatności bezgotówkowe. Nadzór nad procesem wdrażania
            PCI DSS sprawuje pozarządowa organizacja PCI SSC (Payment Card Industry Security
            Standards Council). Certyfikat wydawany jest na okres 12 miesięcy.
          </p>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  cards: getCards(state),
  isLoading: isLoading(state),
})

const withConnect = connect(mapStateToProps, { addCard, setData, reset, reorderCards })
const withProfile = userProfile(false, false)

const composed = compose(withConnect, withProfile)(ChargeCards)

ChargeCards.propTypes = {
  subjectUser: PropTypes.object,
}

export default composed
