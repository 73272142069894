import React, { Fragment } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Cell, Row } from '../../../ui/AccordionTable'
import trans from '../../../../trans'
import { CompanyBaseList } from '../../CompanyBaseList'
import ImportListItem from './ImportListItem'
import { SelectField } from '../../../ui/Form'
import Button from '../../../ui/ButtonComponent'
import {
  fetchCompanies,
  getCompaniesWithAllForSelectOptionsSelector,
} from '../../../../store/app/companies/slice'
import {
  getImportsTotalCount,
  getPage,
  setPage,
  getPaginatedImports,
  isLoading,
  fetchImports,
  createImport,
  setAdding,
  isAdding,
} from '../../../../store/app/imports'

class ImportList extends CompanyBaseList {
  constructor(props) {
    super(props)

    this.state = {
      company: null,
      importType: null,
      file: null,
      editable: {},
      adding: false,
    }

    this.selectCompany = this.selectCompany.bind(this)
    this.selectType = this.selectType.bind(this)
    this.selectFile = this.selectFile.bind(this)
  }
  componentDidMount() {
    const { fetchCompanies, fetchImports } = this.props

    fetchCompanies()
    fetchImports()
  }

  selectFile(event) {
    this.setState({ file: event.target.files[0] })
  }

  selectCompany(value) {
    this.setState({ company: value })
  }

  selectType(value) {
    this.setState({ importType: value })
  }

  addNew() {
    if (!this.canCreate) {
      return
    }

    this.props.setAdding(true)

    const data = new FormData()
    data.append('file', this.state.file)
    if (this.state.company) {
      data.set('company_id', this.state.company)
    }
    data.set('type', this.state.importType)

    this.props.createImport(data).finally(() => {
      this.props.setAdding(false)
      this.setState({
        ...this.state,
        file: null,
        company: null,
        importType: null,
      })
      document.querySelector(`input[type=file]`).value = null
    })
  }

  getIdentifierKey() {
    return 'slug'
  }

  getListItemComponent() {
    return ImportListItem
  }

  renderCompanySelect() {
    const { companies } = this.props
    const { adding } = this.state

    return (
      <Fragment>
        <div className='table__header-select'>
          <span>{trans('instance-users.company-filter')}</span>
          <SelectField
            options={companies}
            value={this.state.company}
            disabled={adding}
            onChange={this.selectCompany}
            placeholder={trans('global.filter-placeholder-choose')}
          />
        </div>
        <div className='table__header-select'>
          <span>{trans('import.type')}</span>
          <SelectField
            options={[
              {
                label: trans('import.type-users'),
                value: 'users',
              },
              {
                label: trans('import.type-user-dimension-items'),
                value: 'user-dimension-items',
              },
              {
                label: trans('import.type-mpks'),
                value: 'mpks',
              },
              {
                label: trans('import.type-providers'),
                value: 'providers',
              },
            ]}
            value={this.state.importType}
            disabled={adding}
            onChange={this.selectType}
            placeholder={trans('global.filter-placeholder-choose')}
          />
        </div>

        <div className='company-settings file-select'>
          {this.state.file && this.state.file.name && <span>{this.state.file.name}</span>}
          <Button
            outline
            xs
            className={'btn--tiny import-button'}
            locked={this.props.isAdding}
            onClick={() => {
              document.querySelector(`input[type=file]`).click()
            }}
            disabled={!this.state.importType}
          >
            {trans('import.select-file')}
          </Button>
          <input
            type='file'
            name='file'
            id='upload'
            style={{ display: 'none' }}
            onChange={this.selectFile}
            accept='text/csv'
          />
        </div>
      </Fragment>
    )
  }

  renderTableContent() {
    const { items } = this.props

    return <Fragment>{items.map((item, index) => this.renderRow(item))}</Fragment>
  }

  renderTableHeader() {
    return (
      <Row>
        <Cell fixedWidth={165}>Data rozpoczęcia</Cell>
        <Cell fixedWidth={165}>Data zakończenia</Cell>
        <Cell fixedWidth='auto'>Rodzaj</Cell>
        <Cell fixedWidth={150}>Firma</Cell>
        <Cell fixedWidth={150}>Dodał</Cell>
        <Cell fixedWidth={150}>Status</Cell>
        <Cell fixedWidth={160} alignCenter></Cell>
      </Row>
    )
  }

  renderHeader() {
    return (
      <div className='table__header'>
        <div className='table__header-actions table__header-actions--small'>
          {this.renderCompanySelect()}

          <Button
            primary
            xs
            className={'btn--tiny import-button'}
            locked={this.props.isAdding}
            onClick={this.addNew}
            disabled={!this.state.importType || !this.state.file}
          >
            {trans('import.import-button-label')}
          </Button>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setPage,
      setAdding,
      fetchCompanies,
      fetchImports,
      createImport,
    },
    dispatch,
  )
}

const mapStateToProps = (state) => {
  return {
    companies: getCompaniesWithAllForSelectOptionsSelector(state, 'all'),
    isLoading: isLoading(state),
    isAdding: isAdding(state),
    items: getPaginatedImports(state),
    total: getImportsTotalCount(state),
    page: getPage(state),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ImportList)
