import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Map } from 'immutable'
import classnames from 'classnames'
import Avatar from '../Avatar/Avatar'
import Ellipsis from '../ui/Ellipsis/Ellipsis'
import TruncateMarkup from 'react-truncate-markup'

interface User {
  first_name: string
  last_name: string
  email: string
  phone?: string
  company: {
    name: string
  }
}

interface Company {
  name: string
}

interface UserProfileProps {
  user: User | Map<keyof User, any>
  company: Company | Map<keyof Company, any>
}

export const UserProfile = (props: UserProfileProps) => {
  const [truncated, setTruncated] = useState(false)
  const userData: User = Map.isMap(props.user) ? (props.user.toJS() as User) : props.user
  const companyData: Company = Map.isMap(props.company)
    ? (props.company.toJS() as Company)
    : props.company

  return (
    <div className='user-profile-card'>
      <Avatar user={userData} className='user-profile-card__avatar' />
      <div className='user-profile-card__content'>
        <h4 className='user-profile-card__header'>
          {userData.first_name} {userData.last_name}
        </h4>
        <span className={classnames('user-profile-card__info', truncated ? 'truncated' : '')}>
          <TruncateMarkup
            onTruncate={(isTruncated) => isTruncated !== truncated && setTruncated(isTruncated)}
            ellipsis={
              <Ellipsis
                interactive={true}
                text={
                  <a className='user-profile-card__email' href={`mailto:${userData.email}`}>
                    {userData.email}
                  </a>
                }
              />
            }
          >
            <span>
              <a className='user-profile-card__email' href={`mailto:${userData.email}`}>
                {userData.email}
              </a>
            </span>
          </TruncateMarkup>

          {userData.phone ? <span className='user-profile-card__phone'>{userData.phone}</span> : ''}
        </span>
        <div>{companyData.name}</div>
      </div>
    </div>
  )
}
