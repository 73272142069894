import React, { Component } from 'react'
import Button from '../ui/ButtonComponent'
import CardsTable from '../UserProfilePage/ChargeCards/partials/CardsTable'
import { Loader } from '../ui/LoadingOverlay/Loader'
import { connect } from 'react-redux'
import trans from '../../trans'
import {
  addCard,
  setData,
  getCards,
  reset,
  setCompanies,
  getCompanies,
} from '../../store/app/charge-cards'
import APIClient from '../../services/APIClient'
import { compose } from 'redux'
import userProfile from '../../store/app/user-profile/providers/userProfile'
import SocketProviderService from '../../utils/SocketProvider'
import { SelectField } from '../ui/Form'
import { Link } from 'react-router-dom'
import { getRouteByName } from '../../routes'
import { BreadCrumbs } from '../../containers'
import { Section } from '../ui/Section'
import { SectionHeader } from '../ui/SectionHeader'

const SOCKET_CHANNEL = 'App.User.'
const SOCKET_EVENT = '.App\\CardManager\\Events\\CorporateCardUpdatedEvent'

class CorporateCards extends Component<any, any> {
  state = {
    isLoading: true,
    companiesList: [],
    isCompanyLoading: true,
  }

  componentDidMount() {
    const {
      userProfile: {
        selectors: { data: user },
      },
    } = this.props

    this.fetchCards()

    SocketProviderService.subscribe(SOCKET_CHANNEL + user.id)(SOCKET_EVENT)(() => {
      this.fetchCards()
    })

    APIClient.getCompanies().then((response) => {
      const selectAll = {
        id: 'all',
        name: trans('global.filter-placeholder-all'),
      }
      this.setState({
        companiesList: [selectAll, ...response.data],
        isCompanyLoading: false,
      })
    })
  }

  componentWillUnmount() {
    const {
      userProfile: {
        selectors: { data: user },
      },
      reset,
    } = this.props
    SocketProviderService.unsubscribe(SOCKET_CHANNEL + user.id)(SOCKET_EVENT)

    reset()
  }

  fetchCards() {
    const { setData } = this.props

    APIClient.getCorporatePaymentCards().then((response) => {
      setData(response.data)
      this.setState({ isLoading: false })
    })
  }

  getCompaniesOptions = () => {
    const { companiesList } = this.state

    return companiesList.map((company) => {
      return {
        value: company.id,
        label: company.name,
      }
    })
  }

  getAllCompaniesId = () => {
    const companiesOptions = this.getCompaniesOptions()
    const allCompaniesId = []

    _.forEach(companiesOptions, (item) => {
      if (item.value !== 'all') {
        allCompaniesId.push(item.value)
      }
    })

    return allCompaniesId
  }

  render() {
    const { addCard, cards, companies, setCompanies } = this.props
    const { isLoading, isCompanyLoading } = this.state
    const isAddButtonBlocked = companies.length <= 0
    const selectedCompany = companies.length > 1 ? 'all' : companies[0]

    return (
      <div>
        <BreadCrumbs>
          <Link to={getRouteByName('main', 'dashboard')}>{trans('global.dashboard')}</Link>
          <Link to={getRouteByName('main', 'instance-settings')}>
            {trans('instance-settings.instance-settings')}
          </Link>
          <Link to={getRouteByName('main', 'instance-cards')}>
            {trans('instance-settings.pay-cards')}
          </Link>
        </BreadCrumbs>

        <Section noBorder>
          <SectionHeader
            className={'charge-cards__header'}
            caption={trans('instance-settings.pay-cards')}
          >
            <div className='charge-cards__header_form'>
              <div className='charge-cards__form_wrapper'>
                {!isCompanyLoading ? (
                  <div className='charge-cards__inline_form'>
                    <span className='filters__input-title'>
                      {trans('instance-users.company-filter')}
                    </span>
                    <SelectField
                      options={this.getCompaniesOptions()}
                      value={selectedCompany}
                      onChange={(value) => {
                        if (value !== 'all') {
                          setCompanies([value])
                        } else {
                          setCompanies(this.getAllCompaniesId())
                        }
                      }}
                      placeholder={trans('global.select-company')}
                    />
                  </div>
                ) : (
                  <Loader />
                )}
              </div>

              <Button
                primary
                className='charge-cards__add-button'
                disabled={isAddButtonBlocked}
                onClick={addCard('corpo', companies)}
              >
                {trans('user.add-card')}
              </Button>
            </div>
          </SectionHeader>

          <div className='charge-cards has-loader'>
            {!isLoading ? <CardsTable cards={cards} withCompany /> : <Loader />}

            <div className='charge-cards__footer'>
              <img
                src='/images/fdp.png'
                alt='Logo First Data Polcard'
                className='charge-cards__certificate-img'
              />
              <img src='/images/pci.png' alt='Logo PCI' className='charge-cards__certificate-img' />
              <p className='charge-cards__clause'>
                Certyfikat zaświadcza, że dane transakcyjne – takie jak dane karty oraz numery PIN –
                przetwarzane we wszystkich punktach oznaczonych logo FirstData Polcard są
                szyfrowane, przesyłane i przechowywane z zachowaniem restrykcyjnych procedur i
                standardów bezpieczeństwa. Minimalizuje to ryzyko oszustw, co jest ważne zarówno dla
                właściciela karty, jak i punktu przyjmującego płatności bezgotówkowe. Nadzór nad
                procesem wdrażania PCI DSS sprawuje pozarządowa organizacja PCI SSC (Payment Card
                Industry Security Standards Council). Certyfikat wydawany jest na okres 12 miesięcy.
              </p>
            </div>
          </div>
        </Section>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  cards: getCards(state),
  companies: getCompanies(state),
})

const mapDispatchToProps = (dispatch, props) => ({
  addCard,
  setData: (data) => dispatch(setData(data)),
  setCompanies: (companies) => dispatch(setCompanies(companies)),
  reset,
})
const withConnect = connect(mapStateToProps, mapDispatchToProps)
const withProfile = userProfile(false, false)

export default compose(withConnect, withProfile)(CorporateCards)
