import { Tooltip as TippyTooltip } from 'react-tippy'
import PropTypes from 'prop-types'
import React from 'react'

function Tooltip(props) {
  if (!props.html) {
    return props.children || null
  }

  return (
    <TippyTooltip
      useContext
      interactive
      position='top'
      className={props.className}
      disabled={props.disabled}
      style={props.style}
      title={props.title}
      arrow={props.arrow}
      sticky={props.sticky}
      theme={props.theme}
      stickyDuration={props.stickyDuration}
      popperOptions={{
        modifiers: {
          preventOverflow: {
            enabled: true,
          },
          hide: {
            enabled: props.hide,
          },
        },
        data: {
          placement: 'top',
          originalPlacement: 'top',
        },
      }}
      html={props.html}
    >
      {props.children}
    </TippyTooltip>
  )
}

Tooltip.propTypes = {
  children: PropTypes.any.isRequired,
  html: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  stickyDuration: PropTypes.number,
  title: PropTypes.string,
  hide: PropTypes.bool,
  sticky: PropTypes.bool,
  arrow: PropTypes.bool,
  theme: PropTypes.string,
}

Tooltip.defaultProps = {
  hide: true,
}

export default Tooltip
export { Tooltip }
