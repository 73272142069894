import { createSelector, createSlice } from '@reduxjs/toolkit'
import APIClient from '../../../services/APIClient'

export const USER_PROFILE_MOUNT_POINT = 'userProfile'

/**
 * In @reduxjs/toolkit we can mutate state in reducers,
 * because behind the scene it uses ImmerJS for applying them as non-mutate
 *
 * https://github.com/immerjs/immer
 */
const slice = createSlice({
  name: USER_PROFILE_MOUNT_POINT,
  initialState: {
    bySlug: {},
    isLoading: false,
    current: null,
  },
  reducers: {
    resetProfile(state) {
      return {
        bySlug: {},
        isLoading: false,
        current: null,
      }
    },
    setProfile(state, action) {
      const profile = action.payload

      state.bySlug[profile.slug] = profile
    },
    setCurrentUser(state, action) {
      state.current = action.payload
    },
    startLoadingUserProfile(state) {
      state.isLoading = true
      state.error = null
    },
    setUserProfileSuccess(state) {
      state.isLoading = false
      state.error = null
    },
    setUserProfileFailed(state, action) {
      state.isLoading = false
      state.error = action.payload
    },
  },
})

export const {
  resetProfile,
  setProfile,
  startLoadingUserProfile,
  setUserProfileFailed,
  setUserProfileSuccess,
  setCurrentUser,
} = slice.actions

export default slice.reducer

// selectors
const getState = (state) => state.get(USER_PROFILE_MOUNT_POINT)
const getUserProfileList = (state) => getState(state).bySlug
export const isUserProfileLoading = (state) => getState(state).isLoading

export const getCurrentUser = createSelector(getState, (state) => {
  return state.bySlug[state.current]
})

export const getCurrentLocale = createSelector(getCurrentUser, (user) => (user ? user.lang : 'pl'))

// memoized selectors, improve performance
export const makeGetUserProfileBySlugSelector = () => {
  return createSelector(
    [(state, _slug) => getUserProfileList(state), (state, slug) => slug],
    (bySlug, slug) => bySlug[slug],
  )
}

export const updateUserGroups = (slug, groupIds) => (dispatch) => {
  return APIClient.updateUserGroups(slug, groupIds).then(({ data }) => {
    dispatch(setUserProfile(data))
  })
}

// action thunks
export const setUserProfile = (profile) => (dispatch) => {
  dispatch(setProfile(profile))
}

export const fetchUserProfile = (slug) => async (dispatch) => {
  dispatch(startLoadingUserProfile())

  try {
    const { data } = await APIClient.getUserProfile(slug)
    dispatch(setProfile(data.user))
    dispatch(setUserProfileSuccess())
  } catch (err) {
    dispatch(setUserProfileFailed(err))
  }
}
