import React from 'react'
import { BreadCrumbs } from '../../containers'
import { Link } from 'react-router-dom'
import { getRouteByName } from '../../routes'
import RouteTabs from '../RouteTabs'
import trans from '../../trans'
import PersonalDataForm from './PersonalData/PersonalDataForm'
import SecurityTab from './Security/SecurityTab'
import ChargeCards from './ChargeCards/ChargeCards'
import Substitutions from './Substitutions/Substitutions'
import { userProfile } from '../../store/app/user-profile/providers/userProfile'
import UserContext from './UserContext'
import { Loader } from '../ui/LoadingOverlay/Loader'
import UserCard from './UserCard'
import { countries } from '../../store/app/countries'
import { compose } from 'redux'
import { Helmet } from 'react-helmet'
import { languages } from '../../store/app/languages'
import { provide as companyHierarchy } from '../../containers/CompanyHierarchy'
import CompanyHierarchy from './CompanyHierarchy'
import MeInCompany from '../CompanyHierarchy/MeInCompany'
import Assistants from './Assistants'

class UserProfilePage extends React.Component<any, any> {
  render() {
    const tabs = [
      {
        id: 'substitutions',
        path: `${this.props.match.path}/substitutions`,
        title: trans('user.substitutions'),
        component: <Substitutions />,
      },
      {
        id: 'assistants',
        path: `${this.props.match.path}/assistants`,
        title: trans('user.assistants-list'),
        component: <Assistants />,
      },
      {
        id: 'personal-data',
        path: `${this.props.match.path}/personal-data`,
        title: trans('user.personal-data'),
        component: (
          <UserContext.Consumer>
            {(value) => (
              <PersonalDataForm
                currentUser
                user={value.user}
                countries={value.countries}
                languages={value.languages}
              />
            )}
          </UserContext.Consumer>
        ),
      },
      {
        id: 'security',
        path: `${this.props.match.path}/security`,
        title: trans('user.security'),
        component: <SecurityTab />,
      },
      {
        id: 'charge-cards',
        path: `${this.props.match.path}/charge-cards`,
        title: trans('user.charge-cards'),
        component: <ChargeCards />,
      },
      {
        id: 'company',
        path: `${this.props.match.path}/company`,
        title: trans('user.company-hierarchy'),
        component: <CompanyHierarchy />,
      },
    ]

    const {
      userProfile: {
        selectors: { data: user, isFetching },
      },
      countries: {
        selectors: { countries },
      },
      languages: {
        selectors: { languages },
      },
    } = this.props

    return (
      <div className='settings-page'>
        <Helmet title={trans('user.page-title')} />

        <BreadCrumbs>
          <Link to={getRouteByName('main', 'dashboard')}>{trans('global.dashboard')}</Link>
          <Link to={getRouteByName('main', 'settings')}>{trans('settings-page.my-profile')}</Link>
        </BreadCrumbs>

        <UserContext.Provider value={{ user: user, countries, languages }}>
          <section className='section section--no-border settings-page__section has-loader'>
            {isFetching && <Loader />}
            <div className='settings-page__account-container'>
              <header className='section__header'>
                <h2 className='h2 section__header-title'>{trans('settings-page.my-profile')}</h2>
              </header>

              <UserContext.Consumer>
                {(value) => <UserCard currentUser user={value.user} />}
              </UserContext.Consumer>

              <RouteTabs items={tabs} defaultTab={tabs[0].id} />
            </div>
            <aside className='settings-page__aside'>
              <MeInCompany />
            </aside>
          </section>
        </UserContext.Provider>
      </div>
    )
  }
}

const withUserProfile = userProfile(false)
const withCountries = countries(false, false)
const withLanguages = languages(false, false)

export default compose(
  companyHierarchy,
  withUserProfile,
  withCountries,
  withLanguages,
)(UserProfilePage)
